import { useAuth } from '../Hooks/useAuth';
import { n2api } from './n2api';
import axios from 'axios';

const useFacebookAuth = () => {

    const { token } = useAuth();

    const getAccessToken = (authorization_code) => {
        const redirect_uri = process.env.REACT_APP_FACEBOOK_REDIRECT_URI;
        return n2api.post(`/facebook/auth`, {
            code: authorization_code,
            redirect_uri: redirect_uri
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
                return {
                    success: false,
                    response: {},
                    error: error
                }
            });
    }

    const storeCredentials = (accessToken, adAccountIds) => {
        const createCredentialDto = {
            accessToken: accessToken,
            adAccountIds: adAccountIds
        };

        // Make a POST request to the n2api backend to store the refresh token
        n2api.put(`/facebook/credentials`, createCredentialDto, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then((response) => {
                console.log('Credentials stored successfully');
                // Handle the response as needed
            })
            .catch((error) => {
                console.error('Error storing Credentials:', error);
                // Handle the error
            });
    }

    const getAdAccountIdResponse = async (accessToken) => {

        const apiVersion = process.env.REACT_APP_FACEBOOK_API_VERSION;
        const api_url = `https://graph.facebook.com/${apiVersion}/me/adaccounts?access_token=${accessToken}`;

        return axios.get(api_url)
            .then(response => {
                if (response.status === 200 || response.status === 201 || response.status === 302) {
                    if (response.data) {
                        return {
                            success: true,
                            adAccountIds: response.data.data,
                            data: response.data
                        }

                    } else {
                        return {
                            success: false,
                            response: response,
                            error: new Error('No ad accounts found')
                        }
                    }
                } else {
                    return {
                        success: false,
                        response: response,
                        error: new Error(response.statusText)
                    }
                }
            })
    }

    const getAdAccountId = async (accessToken) => {

        const response = await getAdAccountIdResponse(accessToken)
        const adAccountIdArray = response.adAccountIds.map(adAccount => adAccount.account_id)

        if (response.success === true) {
            return {
                success: response.success,
                adAccountIds: adAccountIdArray,
                data: response.data
            }
        } else {
            return {
                success: response.success,
                response: response.response,
                error: response.error
            }
        }


    }

    const disconnectFacebook = async () =>  {
        return n2api.delete(`/facebook/credentials`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then((response) => {
                console.log('Disconnected from Facebook successfully');
                if (response.status === 200) {
                    return {
                        success: true,
                        data: response.data
                    }
                } else {
                    return {
                        success: false,
                        response: response,
                        error: new Error(response.statusText)
                    }
                }
                // Handle the response as needed
            })
            .catch((error) => {
                console.error('Error disconnecting from Facebook:', error);
                // Handle the error
            });
    }

    const isConnected = async () =>  {
        return n2api.get(`/facebook/credentials/isConnected`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then((response) => {
                // Handle the response as needed
                return response.data.isConnected
            })
            .catch((error) => {
                console.error('Error checking facebook connection status:', error);
                // Handle the error
            });
    }

    return {
        getAccessToken,
        storeCredentials,
        getAdAccountId,
        disconnectFacebook,
        isConnected
    }

}

export default useFacebookAuth;